import React , {lazy, Suspense }from 'react';

const BofficeRouter = lazy(() => import('./BofficeRouter'))
const FrontRouter = lazy(() => import('./FrontRouter'))

function AppRouter() {
  const pathName = window.location.pathname;
  if (!pathName.startsWith("/boffice")) {
    return (
    <Suspense fallback="..loading">
      <FrontRouter />
    </Suspense>
    );
  } else {
    return (
      <BofficeRouter />
    );
  }
}
export default AppRouter;
